.colu{
  width: 98%;
  border-radius: 10px;
}

.appheader{
 background-color: rgba(255, 255, 255, 0.5);
 -webkit-backdrop-filter: blur(10px);
 margin-bottom: 10px;
}
.con{ 
  padding-right: 15px;
  margin-right: 20px;
  border-radius: 20px;
  width:100% ;
}
.con-1{
  
  border-radius: 30px;
  width:100% ;
}

.shadow {
  border-radius: 30px; 
}
.blur-header {
  backdrop-filter: blur(5px); /* Adjust the blur intensity as needed */
  -webkit-backdrop-filter: blur(5px); /* Safari support */
}
.g-4{
  
  width: 99%; 
  padding-left: 20px;
  padding-bottom: 20px;
  border-radius: 25px;
}

 .h-100{
  
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  
  
}
.text{
  color: #3c4b64;
  height: 30px;
  font-size: 36px;
  padding-left: 40px;
  padding-left: 40px;
  margin-bottom: 30px;
}

.text {
	text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
}
.tra {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);  
    width: 98%;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 15px;
    
}
.coll{
  width: 100%;
}
.open-button {
  position: fixed;
  bottom: 0px;
  right: 0px;
  color: #264b5e;
  padding-bottom: 1%;
    border: none;
    cursor: pointer;
    padding-right: 2%;
    
    
  }
  .chatdiv{
   
    position: fixed;
    bottom: 0px;
    right: 0px;
    padding-bottom: 6%;

  }
  .bot{
    position: absolute;
    z-index: 9999999;
  
  }

  .test {
    padding: 16px 145px;
    opacity: 0.8;
    position: fixed;
    bottom: 1px;
    right: 1px;
   
  }
  /* Three image containers (use 25% for four, and 50% for two, etc) */
.columnnn {
  float: left;
  width: 23.33%;
  padding: 5px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
}

/* Clear floats after image containers */
.row1::after {
  content: "";
  clear: both;
  display: table;
  width: 23.33%;
  
 
}
.columnnn1 {
  float: left;
  width: 23.33%;
  padding: 60px;
  margin-left:5px;
  margin-right:5px;
}
