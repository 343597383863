.container {
  margin: 20px;
}
.oo{
  float: right;
  margin-left: 20px;
 }

.form-group {
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
}

.input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.formation-card {
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.formation-info {
  font-size: 16px;
  color: #666;
 
  margin-bottom: 8px;
}



.star {
  font-size: 24px; /* Taille de l'étoile */
  color: #ddd; /* Couleur de l'étoile vide */
  cursor: pointer;
  margin-right: 5px; /* Espacement entre les étoiles */
}

.star.filled {
  color: gold; /* Couleur de l'étoile pleine */
}

