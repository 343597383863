

.user-card-full {
    overflow: hidden;
}
.img-radius{
    padding-top: 20px;
    width:42% ;
}





.bg-c-lite-green {
    border-radius: 15px;
    background: linear-gradient(to right, #F2A557, #F2A557);
    background-color: rgba(233, 155, 10, 0.555);
   
    
}





.img-radius {
    border-radius: 5px;
}


 










