body, html {
  height: 100% !important;
}

.loginback {
    
 

  background: url(/assets/brand/back1.jpg);

  min-height: 100%;
 
	padding: 20px;
  /* Set up proportionate scaling */
  width: 100%;
  height: 100vh;
	
  /* Set up positioning */
 
 
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
   
   
   
 }
 
 .background {

  background-color: rgba(0,0,0,0.5);
  width:400px !important;
 
  padding: 10px;
  margin:auto;
  border-radius: 15px;
 
 
}
.submitbtn{
  width: 200px !important;
}
 
      


