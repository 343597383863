.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);

}
.center {
  margin: auto;
  width: 50%;
  
  padding: 2px;
  }
